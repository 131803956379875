import face1 from './face1.png'
import face2 from './face2.png'
import face3 from './face3.png'
import face4 from './face4.png'
import face5 from './face5.png'
import face6 from './face6.png'
import face7 from './face7.png'
import face8 from './face8.png'
import face9 from './face9.png'
import face10 from './face10.png'
import face11 from './face11.png'
import face12 from './face12.png'
import face13 from './face13.png'
import face14 from './face14.png'
import face15 from './face15.png'
import face16 from './face16.png'
import face17 from './face17.png'
import face18 from './face18.png'
import face19 from './face19.png'
import face20 from './face20.png'
import face21 from './face21.png'
import face22 from './face22.png'
import face23 from './face23.png'
import face24 from './face24.png'
import face25 from './face25.png'
import face26 from './face26.png'
import face27 from './face27.png'
import face28 from './face28.png'

export default [
  face1,
  face2,
  face3,
  face4,
  face5,
  face6,
  face7,
  face8,
  face9,
  face10,
  face11,
  face12,
  face13,
  face14,
  face15,
  face16,
  face17,
  face18,
  face19,
  face20,
  face21,
  face22,
  face23,
  face24,
  face25,
  face26,
  face27,
  face28,
]
